<template>
  <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-textarea
            id="annotation"
            name="annotation"
            label="Anotação"
            rows="10"
            outlined
            required
            :rules="[required]"
            v-model="model.annotation"
          />
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-row dense>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            height="40"
            :disabled="!valid"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { showMessage } from '@/helpers/messages';
import { InvoiceService } from '@/services/api/invoice';

export default {
  mixins: [rulesMixin],

  data: () => ({
    valid: true,
    model: {
      annotation: null
    }
  }),

  created() {
    this.search();
  },

  computed: {
    ...mapGetters({
      invoice: 'invoice/getInvoice'
    })
  },

  methods: {
    async search() {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getAnnotation(
          this.invoice.id
        );

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const invoiceService = new InvoiceService();
        const { status } = await invoiceService.saveAnnotation(this.model);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
